export enum QuestionDifficulty {
  Basic = 'basic',
  Advanced = 'advanced',
  Excellence = 'excellence'
}

export enum AnswerStatus {
  Positive = 'positive',
  Negative = 'negative',
  NotAvailable = 'notAvailable',
  Empty = 'empty'
}

export type ActionSource = 'morningMeeting' | 'pom' | 'opex' | 'mip'
export const ACTION_SOURCES: ReadonlyArray<ActionSource> = [
  'morningMeeting',
  'pom',
  'opex',
  'mip'
] as const

export const ActionStatus = {
  Pending: 'pending',
  Ongoing: 'ongoing',
  Done: 'done'
} as const

export type ActionStatusType = (typeof ActionStatus)[keyof typeof ActionStatus]

export interface DifficultSummary {
  answeredPositively: number
  questionCount: number
  positivePercent: number
}

export interface Attachment {
  id: number
  fileName: string
  url: string // this is an encrypted url that changes constantly, it can also be used as a document ID
  mediaType: string
  previewUrl?: string
}

export type DifficultySummaries = Record<QuestionDifficulty, DifficultSummary>

export interface Scoring {
  score: number
  completion: number
  difficultySummaries: DifficultySummaries
}

export interface QuestionDto {
  id: string
  difficulty: QuestionDifficulty
  submission?: {
    answer: AnswerStatus
  }
}

export interface TopicDto {
  topicId: string
  sectionId: string
  name: string
  guidanceLink?: string
  scoring?: Scoring
  responsible?: UserDto
  questions?: QuestionDto[]
}

export interface SectionDto {
  sectionId: string
  name: string
  scoring?: Scoring
  responsible?: UserDto
  topics: TopicDto[]
  guidanceLink?: string
}

export interface UserDto {
  userId: string
  name: string
}

export interface PlantDto {
  plantId: string
  name: string
  lastUpdate?: string
  locationId: string
  timezone: string
  scoring: Scoring
  responsible?: UserDto
  sections: SectionDto[]
  guidanceLink: string
}

export type ParentSectionDto = {
  name: string
  sectionId: string
  topics: never[]
}

export type ParentTopicDto = {
  name: string
  sectionId: string
  topicId: string
  parentSection: ParentSectionDto
}

export interface ActionItemDto {
  actionId: number
  plantId: string
  questionId: string
  title: string
  concurrencyStamp: string // contains a UUID
  description: string
  shifthandoverTaskId: string
  assignee?: UserDto
  creator?: UserDto
  dueDate: string
  createdAt: string
  updatedAt: string
  source: ActionSource
  status: ActionStatusType
  attachments?: Attachment[]
  lockQuestionId: boolean
  parentTopic?: ParentTopicDto
}
